body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opi {
  background-color: rgba(196, 229, 127, 0.25);
}
.opi a {
  color: #000;
}

.fixedbutton {
  width: 100%;
  bottom: 0px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixedbutton a {
  padding: 0 0 0 0 !important;
}

.content-full {
  background-attachment: fixed !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.hb {
  width: 100% !important;
}

.title-content {
  background: #000;
  padding: 0.5rem;
  margin: 1rem !important;
}

.title {
  color: #c7e981;
  text-align: center;
}

.subtitle {
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 10px;
  border: solid 1px #000;
}

.notice-title {
  color: #c7e981;
  text-align: left;
}

.wnum {
  color: #c7e981;
}
.wnum a:visited {
  color: #c7e981;
}

.wnumd {
  color: #000;
  font-weight: 600;
}
.wnumd a:visited {
  color: #000;
}

.youtube-icon {
  color: #fff !important;
  background-color: red !important;
}

.white {
  background: #fff;
  padding: 5px;
}

.white-text {
  color: #fff;
}

.gris-trans {
  padding: 5px;
}

.jp-playlist li {
  background-color: #0000007a !important;
}

.green-trans {
  padding: 5px;
}

.transpa {
  background: transparent !important;
}

.img-social {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.img-social img {
  width: 30px;
}

.img-social .img {
  padding-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: center;
  align-content: center;
}

.bg {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-min {
  width: 30%;
}

.avatarip {
  margin: 1rem;
  width: auto !important;
  height: 150px !important;
}

.avataripg {
  margin: 1rem;
  width: 350px !important;
  height: auto !important;
}

.youtube {
  width: 100%;
  height: 50vh;
}

.navbar {
  padding: 0.3rem 0.3rem 0.3rem 5rem;
  background: rgba(0, 0, 0, 0.55) !important;
}

.min100 {
  min-height: 100vh;
}

.navbar button {
  color: #c7e981;
  margin-right: 3rem;
}

.navbar a {
  color: #c7e981;
  margin-right: 3rem;
}

.navbar img {
  max-height: 50px;
}

.fondo-menu {
  background: red;
}

.music-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.music-card-childa {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.music-icon {
  font-size: 3rem !important;
}

.logo-ini {
  max-width: 100px;
  margin-left: 2rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.py-2 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.py-3 {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-3x {
  margin-top: 2rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
}

.icon-radio {
  bottom: 10px;
  position: fixed !important;
  height: 90px !important;
  border-radius: 15px !important;

  img {
    height: 80px;
  }
}
.auspiciadores {
  z-index: 10000000 !important;
  bottom: 0px;
  right: 10px !important;
  max-width: 280px;
  position: fixed !important;
  background: #000;
  height: 100vh;
 .content{
  .face{
    margin-top: 6rem;
  }
 }
  
}

.flot-left {
  left: 10px;
}
.flot-right {
  right: 10px;
}
.float-center {
  left: 50%;
  transform: translateX(-50%);
  width: 500px !important;
  font-size: 1.5rem !important;
}

.carrusel-img {
  height: auto;
  max-height: 150px !important;
  width: 100%;
  
}

.face {
  //background: rgb(177, 34, 34);
  border: none;
 // overflow: hidden;
  z-index: 100000;
}
.prop {
  padding-top: 1rem;
}

@media (min-width: 769px) {
  
  .ini {
    padding-top: 4.8rem;
  }
  .aups-float {
    bottom: 10px;
    position: fixed !important;
    z-index: 100000;
  }
  .aups-float .aups-close {
    position: absolute;
    right: 30px;
    top: 0px;
    padding: 0rem !important;
    z-index: 1000000;
  }

  .media-float {
    bottom: 60px;
    right: 10px;
    width: 50px;
    max-width: 60px !important;
    position: fixed !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .media-float .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .reproductor {
    max-width: 800px !important;
  }

  .notice-img {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 250px;
  }

  .anios30 {
    padding-right: 2rem;
    padding-bottom: 2rem;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .footer {
    background: #000;
    color: #fff;
    padding: 2rem;
  }
}
@media (max-width: 762px){
  .ilexzX,.eKCWik{
    display: none !important;
  }
}

@media (max-width: 768px) {
  .carrusel-img {
    height: auto;
    max-height: 150px !important;
    display: block;
  margin-left: auto;
  margin-right: auto;
    width: auto;
  }
 
  .ausp-sm{
    max-width: 100% !important;
  }
  .aups-float {
    bottom: 50px;
    position: fixed !important;
    z-index: 100000;
  }
  .aups-float .aups-close {
    position: absolute;
    right: 30px;
    top: 0px;
    padding: 0rem !important;
    z-index: 1000000;
  }

  .avataripg {
    margin: 1rem;
    max-width: 90% !important;
    height: auto !important;
  }

  .youtube {
    width: 100%;
    height: auto;
  }

  .media-float {
    max-width: 60px !important;
    top: 20px;
    right: 10px;
    position: fixed !important;
  }

  .notice-img {
    margin: auto;
    display: block;
    max-width: 100%;
  }

  .anios30 {
    padding-right: 0;
    padding-bottom: 0;
  }

  .logo {
    display: contents !important;
  }

  .logo img {
    width: 200px;
    height: 200px;
  }

  .image-min {
    width: 50%;
  }

  .footer {
    background: #000;
    color: #fff;
    padding-bottom: 4rem;
  }
}
.app-link{
  display: flex;
}
.share-container .share-item {
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}
.share-container .share-button:hover:not(:active) {
  opacity: 0.75;
}
.share-container .share-button {
  cursor: pointer;
}
.share-container .share-count {
  margin-top: 3px;
  font-size: 12px;
}

.menu_button {
  position: relative;
}

.popup-content {
  background: rgba(0, 0, 0, 0.55) !important;
}

ul[role="menu"] {
  margin: 0;
  padding: 0;
  position: absolute;
  font-size: 110%;
  list-style: none;
  background-color: #eee;
  display: none;
}

/*# sourceMappingURL=index.css.map */

.load-ips div div {
  background-color: transparent !important;
  height: 50px;
  width: 50px;
  box-shadow: none !important;
}
.load-ips {
  z-index: 10000 !important;
}

.notia a {
  text-decoration: none;
  color: #000 !important;
}

.notia a:hover {
  background: #fff;
  transform: scale(1.05);
  z-index: 1028;
}

.notia a:visited p {
  color: #000 !important;
}

.face {
  border: none !important;
  max-width: 340;
  //height: 180px;
}
.mediaWrapper {
  height: 100%;
  background: #c7e981;
  border-radius: 3px;
  box-shadow: 0px 14px 60px rgba(0, 0, 0, 0.06);
}
.mediaWrapper .mediaImages {
  height: 200px;
  position: relative;
  overflow: hidden;
}
.mediaWrapper .mediaImages img {
  width: 100%;
  transition: all 0.4s;
  transform: scale(1.5);
}
.mediaWrapper .mediaImages button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  min-width: 50px;
  color: #fff;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}
.mediaWrapper .mediaImages button:hover {
  background: #fff;
  color: #fff;
}
.mediaWrapper:hover .mediaImages {
  position: relative;
}
.mediaWrapper:hover .mediaImages img {
  transform: scale(2);
}
.mediaWrapper:hover .mediaImages button {
  visibility: visible;
  opacity: 1;
}
.mediaWrapper .mediaContent {
  padding: 0 20px 10px 20px;
}
.mediaWrapper .mediaContent .text {
  display: block;
  display: -webkit-box;
  height: 20 * 1.4 * 3;
  font-size: 20;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mediaWrapper .mediaContent h4 {
  text-transform: capitalize;
  margin-bottom: 5px;
  font-size: 18px;
}
.youtube {
  width: 100%;
  height: 50vh;
}

.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #bbdc7f;
  letter-spacing: 0.2em;
}
.loader::before,
.loader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #bbdc7f;
  position: absolute;
  -webkit-animation: load 0.7s infinite alternate ease-in-out;
  animation: load 0.7s infinite alternate ease-in-out;
}
.loader::before {
  top: 0;
}
.loader::after {
  bottom: 0;
}
.swal2-container {
  z-index: 10000 !important;
}

@-webkit-keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 40px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 40px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}

.audio-action {
  position: relative;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  svg {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.dYxdcz,
.hPwxla {
  background-color: rgba(255, 255, 255, 0.616) !important;

  box-shadow: 0 0 1px 3px rgb(255, 255, 255) !important;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}
.hHmSte:hover:enabled,
.hHmSte:focus:enabled {
  background-color: rgb(0, 0, 0) !important;
}
.ilexzX,.eKCWik{
  background: #21272C !important;
  color: #fff !important;
}
.hHmSte {
  background: rgba(128, 255, 0, 0.644) !important;
}
.facex {
  border: none !important;
  width: 340px !important;
 // height: 180px;
  border: none;
  
}

.MuiDivider-root {
  height: 3px !important;
  background-color: rgb(78, 78, 78) !important;
}
.vot-ah {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  .cont {
    flex: 2 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    color: #fff;
  }
  img {
    flex: 1 1 auto;
    max-width: 150px;
  }
}
.border-default{
  border-radius: 25px !important;
}
.card-music{
  border-radius: 25px !important;
  display: flex !important;
  flex-direction: row;
  align-content: stretch;
  width: 100%;
  margin: 5px;
  .action{
    width: 40%;
  }
  .cont{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.suge-art{
  width: 100%;
  min-height: 250px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
    align-items: center;
  margin-top: 30px;
  border-radius: 25px !important;
  
}

h2,h3,h4,h5{
  font-weight: 600 !important;
}